import {
  FocusEvent,
  FocusEventHandler,
  FormEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  MouseEventHandler,
} from 'react'
import { Text, Loader } from 'src/ui/components'
import styles from './TextFieldWithLoader.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Flex } from 'src/ui/styles/objects/Flex'
import { isEmpty } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import CheckIcon from 'src/ui/components/atoms/Icon/_icons/system/CheckIcon'

export type CouponValidationMessage =
  | { invalid?: string; valid?: never }
  | { invalid?: never; valid?: string }

interface Props {
  label?: string
  hint?: string
  type?: 'text' | 'email'
  autoFocus?: boolean
  validationMessage?: CouponValidationMessage
  'data-testid'?: string
  ariaLabel?: string
  className?: string
  inputClassName?: string
  clearIconClassName?: string
  onClearInput?: () => void
  onChange?: FormEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  name: string
  min?: string | number
  max?: string | number
  maxLength?: number
  minLength?: number
  pattern?: string
  required?: boolean
  disabled?: boolean
  value?: string
  showLoader?: boolean
}

const TextFieldWithRef: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    label = '',
    hint,
    type = 'text',
    autoFocus = false,
    'data-testid': testId,
    ariaLabel,
    className,
    inputClassName,
    clearIconClassName,
    onClearInput,
    onChange,
    onBlur,
    name,
    validationMessage,
    min,
    max,
    maxLength,
    minLength,
    pattern,
    required,
    disabled = false,
    value,
    showLoader = false,
    ...props
  },
  ref,
) => {
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur?.(e)
  }

  const onMouseDown: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()
  }

  const showClearIcon = !disabled && !isEmpty(value)

  const showErrorMessage = !disabled && Boolean(validationMessage?.invalid)
  const showValidMessage = !disabled && Boolean(validationMessage?.valid)
  const showValidationMessage =
    validationMessage && (showErrorMessage || showValidMessage)

  return (
    <div className={classNames(className, styles.container)}>
      <label htmlFor={name}>
        <Text fontStyle="m-700" color={disabled ? 'disabled' : 'dark'}>
          {label}
        </Text>
        {required && (
          <Text fontStyle="s-700" color="support-error-dark">
            <i aria-hidden="true"> *</i>
          </Text>
        )}
      </label>
      <input
        {...props}
        aria-label={ariaLabel}
        data-testid={testId}
        id={name}
        className={classNames(
          inputClassName,
          styles.input,
          Boolean(validationMessage?.invalid) && styles.inputError,
          Boolean(validationMessage?.valid) && styles.inputValid,
        )}
        type={type}
        autoFocus={autoFocus}
        onChange={onChange}
        onBlur={handleBlur}
        ref={ref}
        name={name}
        min={min}
        max={max}
        maxLength={maxLength}
        minLength={minLength}
        pattern={pattern}
        required={required}
        disabled={disabled}
        value={value}
      />

      {showValidationMessage && (
        <Flex alignItems="flex-start" className="mt-xxs">
          {showErrorMessage ? (
            <Text
              color="support-error-dark"
              fontStyle="s-300"
              ariaLabel={`notification-${type}`}
              data-testid={`notification-icon-${type}`}
            >
              {validationMessage?.invalid}
            </Text>
          ) : (
            <Flex direction="row" gap="xs">
              <Icon size="m" color="support-success" icon={CheckIcon} />
              <Text
                color="support-success"
                fontStyle="s-300"
                ariaLabel={`notification-${type}`}
                data-testid={`notification-icon-${type}`}
              >
                {validationMessage.valid}
              </Text>
            </Flex>
          )}
        </Flex>
      )}

      <Text fontStyle="s-300" color="mid" className={styles.helperText}>
        {hint}
      </Text>

      {showLoader ? (
        <div className={styles.iconWrapper} data-testid="text-field-loader">
          <Loader variant="gradient" size="l" />
        </div>
      ) : showClearIcon ? (
        <button
          type="button"
          className={classNames(clearIconClassName, styles.iconWrapper)}
          onMouseDown={onMouseDown}
          onClick={onClearInput}
          tabIndex={-1}
          data-testid="text-field-clear-button"
        >
          <Icon size="l" color="interactive-primary-active" icon={CloseIcon} />
        </button>
      ) : null}
    </div>
  )
}

export const TextFieldWithLoader = forwardRef(TextFieldWithRef)
