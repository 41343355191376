import { AsideModal, Divider, Text } from 'src/ui/components'
import { createModal } from 'src/ui/hooks/useModal'
import { useTrans } from 'src/ui/hooks/useTrans'
import styles from './CouponsEditorModal.module.scss'
import { FC } from 'react'

interface Props {
  hasToShowFutureCoupons?: boolean
}

export const CouponsEditorModal = createModal(
  ({ hasToShowFutureCoupons = true }: Props) => {
    const { trans } = useTrans(['new-reservation'])

    return (
      <AsideModal title={trans('available-rooms_coupons-summary_title')}>
        <div className={styles.container}>
          <Text as="h2" fontStyle="m-500" className="mb-xs">
            {trans('available-rooms_coupons-summary_add-code_title')}
          </Text>
          <Text as="p" fontStyle="s-300">
            {trans('available-rooms_coupons-summary_add-code_subtitle')}
          </Text>
          <CouponTypeContainer />
          <CouponTypeContainer />
          <Text as="h2" fontStyle="m-500" className="mt-2xl">
            {trans('available-rooms_coupons-summary_add-code_summary')}
          </Text>
          <AppliedCouponsSummary
            hasToShowFutureCoupons={hasToShowFutureCoupons}
          />
        </div>
      </AsideModal>
    )
  },
)

const CouponTypeContainer = () => {
  return <div className={styles.coupontTypeContainer}></div>
}

interface AppliedCouponsSummaryProps {
  hasToShowFutureCoupons: boolean
}

const AppliedCouponsSummary: FC<AppliedCouponsSummaryProps> = ({
  hasToShowFutureCoupons,
}) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <div className={styles.appliedCouponsContainer}>
      <Text as="h2" fontStyle="m-700" className="mb-xs">
        {trans('available-rooms_coupons-summary_applied-coupons')}
      </Text>
      {hasToShowFutureCoupons && (
        <>
          <Divider dir="horizontal" className="mt-m mb-m" />
          <Text as="h2" fontStyle="m-700" className="mb-xs">
            {trans('available-rooms_coupons-summary_future-applied-coupons')}
          </Text>
        </>
      )}
    </div>
  )
}
